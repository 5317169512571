<script lang="ts" setup>
const { duoTone } = useBranding();

defineEmits<{
   (e: 'openAvailabilities'): void;
}>();
</script>

<template>
   <LayoutCard :class="'no-tasks-card'">
      <p class="no-tasks-card__message">{{ $t('my-week.all-tasks-finished') }}</p>

      <ButtonLink
         :icon-name="'calendar-check'"
         :icon-position="'left'"
         :class="'no-tasks-card__link'"
         :text-align="'start'"
         :color="duoTone ? 'var(--nxt-secondary)' : 'var(--nxt-main)'"
         @on-click="$emit('openAvailabilities')"
      >
         {{ $t('my-week.input-availabilities') }}
      </ButtonLink>
   </LayoutCard>
</template>

<style lang="scss" scoped>
.no-tasks-card {
   width: 100%;
   padding: var(--nxt-gutter);
   display: flex;
   flex-direction: column;
   text-align: start;
   gap: var(--nxt-gutter);

   p {
      margin: 0;
      color: var(--nxt-medium-grey);
   }
}

.no-tasks-card__link {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
   padding: 0;
   height: unset;
   margin-top: var(--nxt-gutter-small);
}
</style>
